// https://www.bezkoder.com/react-node-mongodb-auth/
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./scss/main.scss";

// Auth Services
import AuthService from "./services/auth";
import UserService from "./services/user";

// Pages
import PrivateRoute from "pages/PrivateRoute";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Quote from "./views/Misc/Quote";
import Ping from "./views/Misc/Ping";
import Private from "./pages/Private";
import Account from "./pages/Account";
import FileManagement from "./pages/FileManagement";

const App = () => {
  const logOut = () => {
    AuthService.logout();
  };
  const [, setCurrentUser] = useState(undefined);
  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      const decodedJwt = AuthService.parseJwt(user.accessToken);
      if (decodedJwt.exp * 1000 < Date.now()) {
        UserService.getPrivateContent().then(() => {
          const user = AuthService.getCurrentUser();
          if (!user) {
            logOut();
          }
        });
      } else {
        setCurrentUser(user);
      }
    }
  }, []);
  const path = useLocation().pathname;
  const lightweight = ["/"].includes(path);

  return (
    <div className={`${lightweight ? "bg-yellow" : ""}`}>
      <>
        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <FileManagement />
              </PrivateRoute>
            }
          />
          <Route path="/quote" element={<Quote />} />
          <Route path="/ping" element={<Ping />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <Account />
              </PrivateRoute>
            }
          />
          <Route
            path="/private"
            element={
              <PrivateRoute>
                <Private />
              </PrivateRoute>
            }
          />
        </Routes>
      </>
    </div>
  );
};
export default App;
