import React from "react";
import AuthService from "../services/auth";

const Account = () => {
  const currentUser = AuthService.getCurrentUser();
  return (
    <div className="container">
      <header className="jumbotron">
        <h3>
          <strong>{currentUser.account.name}</strong> Profile
        </h3>
      </header>
      <img
        className="App-logo"
        src="./img/drppz_logo_white.png"
        id="drppz-logo"
        alt="drppz Logo"
      />
      <p>
        <strong>Token:</strong> {currentUser.accessToken.substring(0, 20)} ...{" "}
        {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
      </p>
      <p>
        <strong>Id:</strong> {currentUser.id}
      </p>
      <p>
        <strong>Email:</strong> {currentUser.email}
      </p>
    </div>
  );
};
export default Account;
