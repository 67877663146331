import api from "./api";
const getPublicContent = () => {
  return api.get("/test/public");
};
const getPrivateContent = () => {
  return api.get("/test/private");
};
const UserService = {
  getPublicContent,
  getPrivateContent,
};
export default UserService;
