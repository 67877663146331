/* eslint react/prop-types: 0 */
import React from "react";
// import { Link } from "react-router-dom";

const SecondaryHeader = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="altNav">
      <div className="container">
        <a className="navbar-brand" href="#page-top">
          <img src="./img/drppz_logo_white.png" alt="..." />
        </a>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Sign Up
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default SecondaryHeader;
