import api from "./api";

import TokenService from "./token";
const register = (accountName, email, password) => {
  return api.post("/auth/signup", {
    accountName,
    email,
    password,
  });
};
const login = (email, password) => {
  return api
    .post("/auth/signin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }
      return response.data;
    });
};
const logout = () => {
  TokenService.removeUser();
};
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  parseJwt,
};
export default AuthService;
