/* eslint react/prop-types: 0 */
import React, { useState } from "react";
import { BsFillFileCheckFill } from "react-icons/bs";

const FileItem = ({ file, deleteFile, clearCache, user, isLoading }) => {
  const [copyButtonText, setCopyButtonText] = useState("Copy Link");

  const link = `https://storage.drppz.com/${user.account.name}/${file.name}`;

  const copyLink = () => {
    navigator.clipboard.writeText(link);
  };

  const isImg = file.fileType.includes("image");
  let preview = <img src={link}></img>;
  if (!isImg) {
    preview = <BsFillFileCheckFill size={80} />;
  }
  return (
    <>
      <li className="file-item">
        <div className="file-preview">{preview}</div>
        <div>
          <span className="file-header">
            <span>Name:</span> {file.name}
          </span>
          <span className="file-header">
            <span>Link:</span> {link}
          </span>
        </div>
        <div>
          <button
            className="purge-cdn"
            disabled={isLoading}
            onClick={() => {
              copyLink();
              setCopyButtonText("Copied");
              setTimeout(() => {
                setCopyButtonText("Copy Link");
              }, 1000);
            }}
          >
            {copyButtonText}
          </button>

          <button
            className="delete-file"
            disabled={isLoading}
            onClick={() => deleteFile(file)}
          >
            Delete File
          </button>
          <button
            className="purge-cdn"
            disabled={isLoading}
            onClick={() => clearCache(file.name)}
          >
            Purge CDN
          </button>
        </div>
      </li>
    </>
  );
};

export default FileItem;
