import React, { useEffect, useState } from "react";
import api from "../../services/api";

export default function FamousQuote() {
  const [quote, setQuote] = useState("");
  useEffect(() => {
    api.get("/famousquote").then((res) => {
      setQuote(res.data.quote);
    });
  });

  return (
    <>
      <div>{quote}</div>
    </>
  );
}
