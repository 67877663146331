import api from "./api";

const getAccountFiles = () => {
  return api.get("/files/getall");
};

const uploadFile = (formData) => {
  return api
    .post("/files/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
};

const deleteFile = (fileId, fileName) => {
  return api
    .post("/files/delete", {
      fileId,
      fileName,
    })
    .then((res) => {
      return res;
    });
};

const clearCache = (filePath) => {
  return api.delete("/files/clearCache", { data: { filePath } }).then((res) => {
    return res;
  });
};

const FileService = {
  getAccountFiles,
  uploadFile,
  deleteFile,
  clearCache,
};
export default FileService;
