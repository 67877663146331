import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Divider from "../components/Divider";
import SecondaryHeader from "../components/SecondaryHeader";
import CheckButton from "react-validation/build/button";
import AuthService from "../services/auth";
const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger mt-3" role="alert">
        This field is required!
      </div>
    );
  }
};
const Login = () => {
  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    setMessage("");
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(email, password).then(
        () => {
          navigate("/account");
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };
  return (
    <>
      <SecondaryHeader />
      <div className="vh-100" style={{ backgroundColor: "var(--bs-yellow)" }}>
        <div className="container py-5 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-12 col-md-8 col-lg-6 col-xl-5">
              <div
                className="card shadow-2-strong"
                style={{ borderRadius: "1rem" }}
              >
                <div className="card-body p-5">
                  <h3 className="mb-5 text-center">Sign in</h3>
                  <Form onSubmit={handleLogin} ref={form}>
                    <div className="form-outline mb-4">
                      <label
                        className="form-label"
                        style={{ fontWeight: "bold" }}
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <Input
                        type="text"
                        className="form-control form-control-lg"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required]}
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label
                        className="form-label"
                        style={{ fontWeight: "bold" }}
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <Input
                        type="password"
                        className="form-control form-control-lg"
                        name="password"
                        value={password}
                        onChange={onChangePassword}
                        validations={[required]}
                      />
                    </div>
                    <button
                      className="btn btn-dark btn-lg btn-block container-fluid mb-3"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Login</span>
                    </button>
                    {message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )}
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>
                  <Divider classes="my-4" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
