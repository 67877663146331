import React from "react";
import { Navigate } from "react-router-dom";
import TokenService from "../services/token";

// eslint-disable-next-line react/prop-types
function PrivateRoute({ children }) {
  const user = TokenService.getUser();
  return user ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
