import React, { useState, useEffect } from "react";
import FileService from "../services/file";
import Uploader from "../components/Uploader";
import FileItem from "../components/FileItem";
import Spinner from "../components/Spinner";
import AuthService from "../services/auth";

const FileManagement = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [content, setContent] = useState("");
  useEffect(() => {
    setIsLoading(true);
    FileService.getAccountFiles().then(
      (response) => {
        setContent(response.data);
        setIsLoading(false);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();
        setContent(_content);
        setErrorMessage("Unable to fetch files");
      }
    );
  }, []);

  const currentUser = AuthService.getCurrentUser();

  const uploadFile = (formData) => {
    console.log(formData);
    setIsLoading(true);
    FileService.uploadFile(formData).then((res) => {
      setContent((state) => [...state, res.data]);
      setIsLoading(false);
    });
  };

  const deleteFile = (file) => {
    setIsLoading(true);
    const _id = file._id;
    FileService.deleteFile(file._id, file.name).then(() => {
      setContent((content) => content.filter((f) => f._id !== _id));
      setIsLoading(false);
    });
  };

  const clearCache = (fileName) => {
    setIsLoading(true);
    const filePath = `${currentUser.account.name}/${fileName}`;
    FileService.clearCache(filePath).then(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className="container flex-center">
      {errorMessage && <div className="error">{errorMessage}</div>}
      {isLoading && <Spinner />}
      <Uploader uploadFile={uploadFile} />
      {content && (
        <ul id="file-list">
          {content.map((file) => (
            <FileItem
              deleteFile={deleteFile}
              clearCache={clearCache}
              user={currentUser}
              file={file}
              key={file._id}
              isLoading={isLoading}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
export default FileManagement;

// const App = () => {
//   const [list, setList] = React.useState(initialList);

//   function handleRemove(id) {
//     const newList = list.filter((item) => item.id !== id);

//     setList(newList);
//   }

//   return <List list={list} onRemove={handleRemove} />;
// };

// const List = ({ list, onRemove }) => (
//   <ul>
//     {list.map((item) => (
//       <Item key={item.id} item={item} onRemove={onRemove} />
//     ))}
//   </ul>
// );

// const Item = ({ item, onRemove }) => (
//   <li>
//     <span>{item.firstname}</span>
//     <span>{item.lastname}</span>
//     <span>{item.year}</span>
//     <button type="button" onClick={() => onRemove(item.id)}>
//       Remove
//     </button>
//   </li>
// );
