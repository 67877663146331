import React, { useEffect, useState } from "react";
import api from "../../services/api";

export default function Quote() {
  const [message, setMessage] = useState("");
  useEffect(() => {
    api.get("/ping").then((res) => {
      setMessage(res.data.message);
    });
  });

  return (
    <>
      <div>{message}</div>
    </>
  );
}
