import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Uploader = ({ uploadFile }) => {
  const maxSize = 1048576;

  const onDrop = useCallback((acceptedFiles) => {
    // var bodyFormData = new FormData();
    // bodyFormData.append('image', imageFile);

    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append("file", file);
    uploadFile(formData);
  }, []);

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    // acceptedFiles,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: "image/png",
    minSize: 0,
    maxSize,
  });

  const isFileTooLarge =
    rejectedFiles?.length > 0 && rejectedFiles[0].size > maxSize;

  return (
    <div className="container">
      <div id="upload-zone" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive && "Click here or drop a file to upload!"}
        {isDragActive && !isDragReject && "Drop it!"}
        {isDragReject && "File type not accepted, sorry!"}
        {isFileTooLarge && (
          <div className="text-danger">File is too large.</div>
        )}
      </div>
      {/* <ul className="list-group">
        {acceptedFiles.length > 0 &&
          acceptedFiles.map((acceptedFile, idx) => (
            <li key={idx} className="list-group-item list-group-item-success">
              {acceptedFile.name}
            </li>
          ))}
      </ul> */}
    </div>
  );
};

export default Uploader;
